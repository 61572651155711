import React from "react"

export default function Section7() {
  return (
      <div className="w-full p-10 lg:py-20">
          <p className="text-3xl text-black text-left">
            OUR CLIENTS
          </p>
          <p className="text-5xl text-black font-bold text-left text-aileron-bold">
            Who we've worked with
          </p>
          <div className="divider bg-black my-10"></div>
          <div class="flex text-center flex-wrap w-5/6 mx-auto py-20">
            
            <div class="w-1/2 px-2 self-center">
                <img 
                    className="mx-auto tint-grayscale-100 hover-tint-grayscale-0"
                    src={require("../../../assets/images/logo-npdc-hover.png")}
                    alt=""
                    // width="295"
                />
            </div>
            <div class="w-1/2 px-2 self-center tint-grayscale-100 hover-tint-grayscale-0">
                <img 
                    className="mx-auto"
                    src={require("../../../assets/images/logo-mtq-hover.png")}
                    alt=""
                    // width="295"
                />
            </div>
            {/* <div class="w-1/3 px-2 self-center tint-grayscale-100 hover-tint-grayscale-0">
                <img 
                    className="mx-auto"
                    src={require("../../../assets/ciergio_web_antel.png")}
                    alt=""
                    // width="295"
                />
            </div> */}
            
           
            
          </div>

      </div>
  )
}